import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import CardModal from "./CardModal";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const LiveAuction = React.forwardRef((props, ref) => {
  const data = props.data;

  const [modalShow, setModalShow] = useState(false);
  const [isMale, setIsMale] = useState(true);

  return (
    <Fragment>
      <section className="tf-section live-auctions" ref={ref}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-20">Explore ZT Islanders</h2>
                {isMale ? (
                  <Link
                    to="#"
                    onClick={() => setIsMale(false)}
                    className="exp style2"
                  >
                    Toggle Female
                  </Link>
                ) : (
                  <Link
                    to="#"
                    onClick={() => setIsMale(true)}
                    className="exp style2"
                  >
                    Toggle Male
                  </Link>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <p className="tf-title pb-20">
                <b>ZT (Zombie Turtle) Islanders</b> are the natives that live on
                Zombie Turtle Island. These are unique <b>Hero NFTs</b> that
                must be minted to play the game. You will also be able to mint
                other unique NFTs like: Gears, Consumables, Resources, Pets, and
                Land. <b>NFT minting</b> and <b>Market Place</b> will be a
                available soon.
              </p>
            </div>
            <div className="col-md-12">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 4,
                  },
                }}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
              >
                {data.slice(0, 9).map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-container show-shadow carousel auctions">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="slider-item">
                            <div className="sc-card-product">
                              <div className="card-media">
                                <img
                                  src={isMale ? item.img.male : item.img.female}
                                  alt="axies"
                                />
                              </div>
                              <div className="card-title">
                                <h5>{item.title}</h5>
                              </div>
                              <div className="meta-info">
                                <div className="author">
                                  <div className="info">
                                    <span></span>
                                    <h6> {item.description}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
});

LiveAuction.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LiveAuction;
