import React, { useRef } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import heroSliderData from "../assets/data/data-slider";
import Slider from "../components/slider/Slider";
import characterRaceData from "../assets/data/data-character-races";
import CharacterRaces from "../components/layouts/CharacterRaces";
import Team from "../components/layouts/Team";
import teamData from "../assets/data/data-team";
import About from "../components/layouts/About";
import Tokenomics from "../components/layouts/Tokenomics";
import Roadmap from "../components/layouts/Roadmap";
import roadmapData from "../assets/data/data-roadmap";
import HowToBuy from "../components/layouts/HowToBuy";

const Home01 = () => {
  const aboutRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const howToBuyRef = useRef(null);
  const teamRef = useRef(null);
  const roadmapRef = useRef(null);
  const nftRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === "About") {
      aboutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (section === "Tokenomics") {
      tokenomicsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (section === "Team") {
      teamRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (section === "Roadmap") {
      roadmapRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (section === "NFTs") {
      nftRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      howToBuyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <div className="home-1">
      <Header scrollToSection={scrollToSection} />
      <Slider data={heroSliderData} />
      <About ref={aboutRef} />

      <Tokenomics ref={tokenomicsRef} />
      <CharacterRaces data={characterRaceData} ref={nftRef} />

      <Team data={teamData} ref={teamRef} />
      <Roadmap data={roadmapData} ref={roadmapRef} />
      <HowToBuy ref={howToBuyRef} />
      <Footer />
    </div>
  );
};

export default Home01;
