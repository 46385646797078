import img1 from "../images/slider/slide_1.png";
import img3 from "../images/slider/slide_3.png";
import imgbg1 from "../images/slider/bg_slide_1.png";
import transparentBG from "../images/slider/bg_slide_1_transparent.png";

const heroSliderData = [
  {
    title_1: "Welcome to",
    title_2: "Zombie Turtle",
    title_3: "Discover $ZTURT",
    description:
      "The one and only meme coin that can never die. $zTURT will live on forever as an undead even during the bear market when all other meme coins die.  ",
    img: img1,
    imgbg: imgbg1,
    class: "left",
  },

  {
    title_1: "Explore, venture,",
    title_2: "& Save",
    title_3: "ZT Island",
    description:
      "Play to earn in Zombie Turtle Island, a virtual world ecosystem.  ",
    img: img3,
    imgbg: transparentBG,
    class: "right",
  },
];

export default heroSliderData;
