import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";

const Roadmap = React.forwardRef((props, ref) => {
  const data = props.data;
  return (
    <section className="tf-section popular-collection" ref={ref}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-22 text-left">Roadmap</h2>
              <a
                href="https://docs.zombieturtle.vip"
                target="_blank"
                rel="noreferrer"
                className="exp style2"
              >
                Whitepaper
              </a>
            </div>
          </div>
          <div className="col-md-12">
            <div className="collection">
              <Swiper
                modules={[Navigation, Scrollbar, A11y]}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },

                  767: {
                    slidesPerView: 2,
                  },

                  991: {
                    slidesPerView: 3,
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <RoadmapItem item={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
Roadmap.propTypes = {
  data: PropTypes.array.isRequired,
};

const RoadmapItem = (props) => (
  <div className="swiper-container show-shadow carousel4 button-arow-style">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="slider-item">
          <div
            className="sc-card-collection style-2 home2"
            style={{ minHeight: 400 }}
          >
            <div className="card-bottom">
              <div className="author">
                <div className="content">
                  <h4>{props.item.title}</h4>
                </div>
              </div>
            </div>

            <div className="content">
              <ul>
                {props.item.data.map((entry, idx) => (
                  <li key={idx}>
                    <p>- {entry}</p>{" "}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Roadmap;
