import React from "react";
import { Link } from "react-router-dom";

const Tokenomics = React.forwardRef((props, ref) => {
  const data = [
    {
      title: "Liquidity on Uniswap",
      description:
        "48,500,000 zTURT allocated for Liquidity pool on a the Uniswap DEX. This full amount will be minted initially for this purpose. ",
      percentage: "48.5%",
      colorbg: "icon-color3",
    },
    {
      title: "Founders/Development",
      description:
        "11,000,000 zTURT will be allocated to founders and for the development the project. Only 1,000,000 will be minted at initial.  The other 10M can only be earned when players start earning in the game. ",
      percentage: "11%",
      colorbg: "icon-color2",
    },
    {
      title: "Marketing",
      description:
        "5,500,000 zTURT will be allocated for airdrops and marketing.  Only 500,000 will be minted initially.  The other 5M can only be earned when player starts earning in the game. ",
      percentage: "5.5%",
      colorbg: "icon-color1",
    },
    {
      title: "Play-to-Earn",
      description:
        "35,000,000 zTURT will be locked until game is ready.  Then it will be used as an important play to earn component.  Players earn ztCOIN, then swaps it for zTURT. ",
      percentage: "35%",
      colorbg: "icon-color4",
    },
  ];
  return (
    <section
      className="tf-box-icon live-auctions tf-section style7 bg-style"
      ref={ref}
    >
      <div className="themesflat-container">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="heading-live-auctions style2 mg-t-3 mg-bt-22">
              <h2 className="heading-fill mg-bt-16">Tokenomics</h2>
              <h5 className="tf-title text-left pb-15">
                100 Million zTURT - Ecosystem Token <br />
                Unlimited ztCOIN - Play-to-Earn Token
              </h5>
              <p className="content">
                <b>zTURT</b> is the ecosystem, governance, and shareholder
                token.
              </p>{" "}
              <br />
              <p className="content">
                <b>ztCOIN</b> is the play-to-earn in-game token. You can only
                get ztCOIN by playing the game. When the game launches, there a
                swap tool called the zTURT Mint Protocol for players to swap
                their ztCOIN for zTURT, and vice-versa. The ratio will start at
                1,000,000 ztCOIN to 1 zTURT, and will use the same formula as
                Uniswap.
              </p>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12">
            <div className="sc-box-icon-inner style3">
              {data.map((item, index) => (
                <TokenomicsItem key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

const TokenomicsItem = (props) => (
  <div className="sc-box-icon">
    <div className="image">
      <div
        className={`icon-create ${props.item.colorbg} `}
        style={{ fontSize: "18px", color: "white", fontWeight: 600 }}
      >
        {props.item.percentage}
      </div>
    </div>
    <div className="wrap-box">
      <h3 className="heading">
        <Link to="/wallet-connect">{props.item.title}</Link>
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
);

export default Tokenomics;
