import React from "react";
import icon1 from "../../assets/images/icon/Wallet.png";
import icon2 from "../../assets/images/icon/ethereum.png";
import icon3 from "../../assets/images/icon/uniswap.png";
import icon4 from "../../assets/images/icon/swap.png";

const HowToBuy = React.forwardRef((props, ref) => {
  const data = [
    {
      title: "Create a Wallet",
      description:
        "Download Metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.",
      icon: icon1,
      colorbg: "icon-color1",
      link: "https://metamask.io/",
    },
    {
      title: "Get Some ETH",
      description:
        "Have ETH in your wallet to switch to $ZTURT. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.",
      icon: icon2,
      colorbg: "icon-color2",
      link: "https://portfolio.metamask.io/buy/payment-method",
    },
    {
      title: "Go to Uniswap",
      description:
        "connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $ZTURT token address into Uniswap, select ZTURT, and confirm. When Metamask prompts you for a wallet signature, sign.",
      icon: icon3,
      colorbg: "icon-color3",
      link: "https://app.uniswap.org/#/swap",
    },
    {
      title: "Swap ETH for $ZTURT",
      description:
        "Swap ETH for $ZTURT. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.",
      icon: icon4,
      colorbg: "icon-color4",
      link: "https://app.uniswap.org/#/swap",
    },
  ];
  return (
    <section className="tf-box-icon create style1 tf-section" ref={ref}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions mg-bt-22">
              <h2 className="tf-title pb-17">How to Buy</h2>
            </div>
          </div>
          {data.map((item, index) => (
            <HowToBuyItem key={index} item={item} />
          ))}
        </div>

        <iframe
          _ngcontent-sas-c3=""
          title="Uniswapv3"
          src="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x799914195832de9F7293bBcB10074869bE9d319e"
          width="100%"
          style={{
            border: "3px solid",
            margin: "auto",
            borderRadius: "1rem",
            width: "100%",
            minHeight: "600px",
            borderColor: "purple",
            marginTop: "25px",
          }}
        ></iframe>

        <h3 style={{ marginTop: 50 }}>Investment Disclaimer: </h3>
        <p>
          We urge you to do your own research. DYOR (Do Your Own Research) is
          not a rare saying in the blockchain industry. Managing your risk is an
          important key when investing into any projects. As this project does
          aim to have intrinsic value, we cannot and will not guarantee ROI.
          Never invest more than you can lose - you alone are responsible for
          your investment. Zombie Turtle Island is NOT responsible for any
          losses or changes in token price, and NOT responsible for any lost
          funds due to smart contract error or exploits. The team at Zombie
          Turtle Island are committed to creating an immersive and fun virtual
          world experience that blends together the meme/game theory. Not only
          that, we will be heavily vested in preserving sea turtles in the
          world. We believe this makes for a unique, fun, and impactful
          experience for our player and shareholder community. We are all in on
          this project and here to stay for the long haul. Our goal is to
          essentially give you value.{" "}
        </p>
        <br />
        <h3 style={{ marginTop: 50 }}>Non-affliation Disclaimer</h3>
        <p>
          We are not directly affiliated with the Sea Turtle conservancy
          organization based in Gainesville, Florida, at this point. They do not
          sponsor us or fund us in anyway, and we are not entitled to provide
          continued funding for their efforts. We are simply trying to make a
          difference by contributing in our own way. Should there be any
          opportunity in the future for partnering up with our friends, it
          should be decided by the Zombie Turtle Island community. We are also
          not affiliated with any certain individuals such as, Mr. Jonathan
          Ware, nor do we sponsor or promote any specific activity or event
          hosted by Mr. Ware or his family. The meme token itself is simply
          paying homage to the sensational meme that went viral in the year
          2007. We saw this as an opportunity to do something impactful and
          meaningful for the world.{" "}
        </p>
      </div>
    </section>
  );
});

const HowToBuyItem = (props) => (
  <div className="col-lg-3 col-md-6 col-12">
    <div className="sc-box-icon">
      <div className="image">
        <div className={`icon-create ${props.item.colorbg}`}>
          <img src={props.item.icon} alt="" />
        </div>
      </div>
      <h3 className="heading">
        <a href={props.item.link} target={"_blank"} rel="noreferrer">
          {props.item.title}
        </a>
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
);

export default HowToBuy;
