import React from "react";
import PropTypes from "prop-types";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png";
import imgbg from "../../assets/images/backgroup-secsion/img_bg_page_title.jpg";
import goldCoin from "../../assets/images/backgroup-secsion/gold-coin.png";
import silverCoin from "../../assets/images/backgroup-secsion/silver-coin.png";

const Slider = (props) => {
  const data = props.data;
  return (
    <div className="mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <SliderItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};
const SliderItem = (props) => (
  <div className="flat-title-page" style={{ backgroundImage: `url(${imgbg})` }}>
    <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
    <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
    <img className="bgr-gradient gradient3" src={shape3} alt="Axies" />
    <img src={goldCoin} className="shape item-w-16" alt="goldCoin" />
    <img src={goldCoin} className="shape item-w-22" alt="goldCoin" />
    <img src={silverCoin} className="shape item-w-32" alt="goldCoin" />
    <img src={silverCoin} className="shape item-w-48" alt="goldCoin" />
    <img src={goldCoin} className="shape style2 item-w-51" alt="goldCoin" />
    <img
      src={silverCoin}
      className="shape style2 item-w-51 position2"
      alt="goldCoin"
    />
    <img src={goldCoin} className="shape item-w-68" alt="goldCoin" />
    <div className="overlay"></div>
    <div className="swiper-container mainslider home">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="slider-item">
            <div className="themesflat-container ">
              <div className="wrap-heading flat-slider flex">
                <div className="content">
                  <h2 className="heading">{props.item.title_1}</h2>
                  <h1 className="heading mb-style">
                    <span className="tf-text s1">{props.item.title_2}</span>
                  </h1>
                  <h1 className="heading">{props.item.title_3}</h1>
                  <p className="sub-heading">{props.item.description}</p>
                  <div className="flat-bt-slider flex style2">
                    <a
                      href="https://docs.zombieturtle.vip"
                      target={"_blank"}
                      rel="noreferrer"
                      className="sc-button header-slider style style-1 note fl-button pri-1"
                    >
                      <span>Read Whitepaper</span>
                    </a>
                    <a
                      href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x799914195832de9F7293bBcB10074869bE9d319e"
                      target={"_blank"}
                      rel="noreferrer"
                      className="sc-button header-slider style style-1 credit-card fl-button pri-1"
                    >
                      <span>Buy Now</span>
                    </a>
                    
                  </div>
                </div>
                <div className="image">
                  <img className="img-bg" src={props.item.imgbg} alt="axies" />
                  <img src={props.item.img} alt="axies" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Slider;
