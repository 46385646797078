import img1_M from "../images/character-races/human-male.png";
import img2_M from "../images/character-races/bird-male.png";
import img3_M from "../images/character-races/rabbit-male.png";
import img4_M from "../images/character-races/fox-male.png";
import img5_M from "../images/character-races/wolf-male.png";
import img6_M from "../images/character-races/cat-male.png";
import img7_M from "../images/character-races/lizard-male.png";
import img8_M from "../images/character-races/fish-male.png";
import img9_M from "../images/character-races/mecha-male.png";

import img1_F from "../images/character-races/human-female.png";
import img2_F from "../images/character-races/bird-female.png";
import img3_F from "../images/character-races/rabbit-female.png";
import img4_F from "../images/character-races/fox-female.png";
import img5_F from "../images/character-races/wolf-female.png";
import img6_F from "../images/character-races/cat-female.png";
import img7_F from "../images/character-races/lizard-female.png";
import img8_F from "../images/character-races/fish-female.png";
import img9_F from "../images/character-races/mecha-female.png";

const characterRaceData = [
  {
    img: {
      male: img1_M,
      female: img1_F,
    },
    title: "Human",
    description: "The good old human race. ",
  },
  {
    img: {
      male: img2_M,
      female: img2_F,
    },
    title: "Lacerteon",
    description: "The bird race. ",
  },
  {
    img: {
      male: img3_M,
      female: img3_F,
    },
    title: "Lepeon",
    description: "The rabbit race. ",
  },
  {
    img: {
      male: img4_M,
      female: img4_F,
    },
    title: "Vulpeon",
    description: "The fox race. ",
  },
  {
    img: {
      male: img5_M,
      female: img5_F,
    },
    title: "Canineon",
    description: "The wolf race. ",
  },
  {
    img: {
      male: img6_M,
      female: img6_F,
    },
    title: "Felineon",
    description: "The cat race. ",
  },
  {
    img: {
      male: img7_M,
      female: img7_F,
    },
    title: "Reptileon",
    description: "The lizard race. ",
  },
  {
    img: {
      male: img8_M,
      female: img8_F,
    },
    title: "Aquareon",
    description: "The fish race. ",
  },
  {
    img: {
      male: img9_M,
      female: img9_F,
    },
    title: "Mecha Humans",
    description: "The cyborg humanoids. ",
  },
];

export default characterRaceData;
