import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logodark from "../../assets/images/logo/logo_dark.png";
import logofooter from "../../assets/images/logo/logo2.png";
import TwitterLogo from "../../assets/images/socials/twitter.png";
import DiscordLogo from "../../assets/images/socials/discord.png";
import EtherScanLogo from "../../assets/images/socials/etherscan.png";
import DextoolsLogo from "../../assets/images/socials/dextools.png";
import TGLogo from "../../assets/images/socials/telegram.png";

const Footer = () => {
  const resourcesList = [
    {
      title: "Whitepaper",
      link: "https://docs.zombieturtle.vip/",
    },
    {
      title: "Contracts",
      link: "https://docs.zombieturtle.vip/important-info/contract-addresses",
    },
  ];
  const companyList = [
    {
      title: "info@zombieturtle.vip",
      link: "mailto:info@zombieturtle.vip",
    },
  ];
  const socialList = [
    {
      link: "https://twitter.com/ZombieTurtleEth",
      logo: TwitterLogo,
    },
    {
      link: "https://discord.gg/DyBhM5Sh8Z",
      logo: DiscordLogo,
    },
    {
      link: "https://etherscan.io/token/0x799914195832de9f7293bbcb10074869be9d319e",
      logo: EtherScanLogo,
    },
    {
      link: "https://www.dextools.io/app/en/ether/pair-explorer/0x1f91edf6d467ea02e9191fead9bf5c01a4a59e83",
      logo: DextoolsLogo,
    },
    {
      link: "https://t.me/officialzombieturtle",
      logo: TGLogo,
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div>
      <footer id="footer" className="footer-light-style clearfix bg-style">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="widget widget-logo">
                <div className="logo-footer" id="logo-footer">
                  <Link to="/">
                    <img
                      className="logo-dark"
                      id="logo_footer"
                      src={logodark}
                      alt="nft-gaming"
                    />
                    <img
                      className="logo-light"
                      id="logo_footer"
                      src={logofooter}
                      alt="nft-gaming"
                    />
                  </Link>
                  <div className="widget-social style-1 mg-t32">
                    <ul>
                      {socialList.map((item, index) => (
                        <li key={index}>
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <img
                              className={item.icon}
                              src={item.logo}
                              alt="socialIcon"
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p style={{ marginTop: 30 }}>
                      © {new Date(Date.now()).getFullYear()} Zombie Turtle Team.
                      All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-7 col-7">
              <div className="widget widget-menu style-2">
                <h5 className="title-widget">Resources</h5>
                <ul>
                  {resourcesList.map((item, index) => (
                    <li key={index}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-5 col-5">
              <div className="widget widget-menu fl-st-3">
                <h5 className="title-widget">Contact Us</h5>
                <ul>
                  {companyList.map((item, index) => (
                    <li key={index}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}

      <div
        className="modal fade popup"
        id="popup_bid"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body space-y-20 pd-40">
              <h3>Place a Bid</h3>
              <p className="text-center">
                You must bid at least{" "}
                <span className="price color-popup">4.89 ETH</span>
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="00.00 ETH"
              />
              <p>
                Enter quantity. <span className="color-popup">5 available</span>
              </p>
              <input type="number" className="form-control" placeholder="1" />
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <p> You must bid at least:</p>
                <p className="text-right price color-popup"> 4.89 ETH </p>
              </div>
              <div className="d-flex justify-content-between">
                <p> Service free:</p>
                <p className="text-right price color-popup"> 0,89 ETH </p>
              </div>
              <div className="d-flex justify-content-between">
                <p> Total bid amount:</p>
                <p className="text-right price color-popup"> 4 ETH </p>
              </div>
              <Link
                to="#"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#popup_bid_success"
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                Place a bid
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
