import React, { useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import ReactPlayer from "react-player";

const About = React.forwardRef((props, ref) => {
  const [data] = useState([
    {
      key: "0",
      show: "show",
      title: "Where did the meme come from?",
      text: "There was a video meme that went viral in 2007, of a boy named, Jonathan Ware, who was face-painted as a zombie while at a local fair.  When asked to comment about his zombie makeover by a reporter, he replied randomly with, “I like turtles”.  This was not the response that the reporter had expected and so she tried to alleviate the awkward situation by wrapping up the segment swiftly.  This video became a sensation during that time and many merchandises were sold with the tag line, “I like turtles.”  Our branding stems off of the Zombie boy's response,  “I like turtles.”  We are sure he is not the only person in the world who likes turtles.  ",
    },
    {
      key: "1",
      title: "What is our impactful mission?",
      text: `
      Our team believes that it is important to give back to the world from which we took a lot from.  Although the problems are vast, the reality is we cannot target every area having to do with world preservation.  Therefore, we decided to focus on one relevant subject...saving our sea turtle friends.  This goal and mission goes hand in hand with the message of the meme.  
      Fact: Over the last 200 years, human activities have tipped the scales against the survival of our ancient turtle friends, causing near extinctions for a lot of these creatures. 
      As part of our overall mission and initiative, we plan on donating 20% of all earned revenue to the Sea Turtle conservancy, which is the oldest and most accomplished sea turtle organization in the world!  They are a non-profit organization based in Gainesville, Florida.  
      If you would like to invest in a meaningful project that can impact the world positively, and/or if you have the mentality of, “I like turtles”, support our initiative by buying zTURT to help us fund this innovative project.  Become a stakeholder and help spread awareness to preserve the life and ecosystem of the sea turtles. 
      `,
    },
    {
      key: "2",
      title: "What will be the utility for zTURT?",
      text: `After years of studying the trends and fads of blockchain, we became big fans of the innovations happening around technology like, Play-to-Earn, Metaverse, Memes and NFT.  We wanted to create an immersive world that can bring impactful awareness to our community about the things happening to our sea turtle friends.  For this reason, the team has decided to build our entire ecosystem around a protocol called, Zombie Turtle Island Protocol (ZTIP).  
      Zombie Turtle Island is (or will be) a virtual world ecosystem that creates various tangible and intangible value with gaming NFT as the core.  The aim is to build an expandable virtual world that offers players various ways to earn and acquire wealth.  Furthermore, the players get to learn about preserving the life of sea turtles inside the game and will play a key role in spreading awareness. zTURT will be the ecosystem token that powers the game economy. `,
    },
  ]);
  return (
    <div>
      <section className="tf-section wrap-accordion" ref={ref}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                About
              </h2>
              <h5 className="sub-title help-center mg-bt-32 ">
                Zombie Turtle Token (zTURT) is one of the few projects that can
                confidently say, "We have a meme with a team." The team behind
                the token consist of a group of bright-minded IT gurus,
                engineers, business owners, and crypto enthusiasts. We want to
                give REAL utility to Zombie Turtle and not spout nonsense like,
                "This token has no intrinsic value...ya-dee-ya-da x2."
              </h5>
            </div>

            <div className="col-md-12">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=CMNry4PE93Y"
                style={{
                  margin: "auto",
                  marginBottom: 10,
                  boxShadow: "2px 2px 4px black",
                }}
              />
            </div>
            <div className="col-md-12">
              <div className="flat-accordion2">
                {data.map((item, index) => (
                  <Accordion key={index} title={item.title}>
                    <p>{item.text}</p>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default About;
