import img1 from "../images/avatar/avt-1.jpg";
import img2 from "../images/avatar/avt-2.jpg";
import img3 from "../images/avatar/avt-3.jpg";
import img4 from "../images/avatar/avt-4.jpg";
import img5 from "../images/avatar/avt-5.jpg";
import img6 from "../images/avatar/avt-6.jpg";
import img7 from "../images/avatar/avt-7.jpg";
import img8 from "../images/avatar/avt-8.jpg";

const teamData = [
  {
    img: img1,
    name: "Doffy",
    position: "CEO - Visionary",
  },
  {
    img: img2,
    name: "Oppa",
    position: "CMO - Marketer",
  },
  {
    img: img3,
    name: "Shiroe",
    position: "CTO - Developer ",
  },
  {
    img: img4,
    name: "Maple Su-tori",
    position: "CIO - Data Analyst",
  },
  {
    img: img5,
    name: "Manga Ninja",
    position: "CCO - Story Writer",
  },
  {
    img: img6,
    name: "Baptiste",
    position: "COO - Game Designer",
  },
  {
    img: img7,
    name: "Berri",
    position: "CFO - Finance Analyst ",
  },
  {
    img: img8,
    name: "Oturan",
    position: "CDO - Digital Producer",
  },
];

export default teamData;
