const roadMapData = [
  {
    title: "Phase I",

    data: [
      "Soft launch the project with 48,500,000 zTURT paired with ETH in a Liquidity Pool.  ",
      "List on Uniswap",
      "Reach over 1000 Token holders",
      "Run one big and successful marketing campaign",
      "Airdrop events on Social Media",
      "List on CoinMarketCap",
    ],
  },
  {
    title: "Phase II",

    data: [
      "Launch Shareholder Status Contract for Staking.",
      "Airdrop Generation Zero Hero NFTs to 100 random Active Shareholders via a raffle system.",
      "Launch minting contract for Generation Zero Hero NFTs",
      "Send our first donation to the Sea Turtle conservancy",
      "Reach 10,000 token holders",
      "Partner with big Social Media Influencers",
    ],
  },
  {
    title: "Phase III",

    data: [
      "Launch expandable/beta version of Zombie Turtle Island.  ",
      "Launch ztCOIN play to earn in-game currency token.  ",
      "Launch Player to Player Free Market Exchange ",
      "Launch zTURT Mint Protocol (swapping ztCOIN for zTURT)",
      "Launch NFT, Metaverse, Blockchain Academy for the beginners in Cyrpto. ",
      "List on Top Centralized Exchanges",
      "Reach over 100,000 token holders",
      "Reach over 100,000 players  ",
    ],
  },
  {
    title: "Phase IV",

    data: [
      "Launch alpha version of Zombie Turtle Island  ",
      "Governance system",
      "Discuss game ideas/updates with community and vote on one community driven game and/or update",
    ],
  },
  {
    title: "Phase V",

    data: [
      "More games and more partnerships, possible with the Sea Turtle conservancy ",
      "Develop ZTI SDK to foster a community of developers/hobbyist in the ecosystem. ",
      "TBA",
    ],
  },
];

export default roadMapData;
