const menus = [
  {
    id: 1,
    name: "About",
    links: "#",
  },
  {
    id: 2,
    name: "Team",
    links: "#",
  },
  {
    id: 3,
    name: "Tokenomics",
    links: "#",
  },
  {
    id: 4,
    name: "NFTs",
    links: "#",
  },
  {
    id: 5,
    name: "Roadmap",
    links: "#",
  },
  {
    id: 6,
    name: "How to Buy",
    links: "#",
  },
];

export default menus;
